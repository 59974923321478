<template>
  <section class="card relative w3-white mb-4">
    <button
      class="w3-button w3-red w3-round w3-round-xxlarge absolute"
      style="top: -10px; right: -10px"
      @click="closeDetial"
    >
      x
    </button>
    <div class="card-body bg-transparent m-0 p-4">
      <h4 class="bold mb-3 ellipsis">
        {{ subaccount.FirstName }} {{ subaccount.Surname }}
      </h4>
      <div class="row mb-3">
        <div class="col-md-3">
          <p class="m-0 size12">Contact No</p>
          <p class="mt-0 mb-2 bold w3-text-black">
            {{ subaccount.SubContactNo }}
          </p>
        </div>
        <div class="col-md-3">
          <p class="m-0 size12">Phone Number</p>
          <p class="mt-0 mb-2 bold w3-text-black">
            {{ subaccount.PhoneNumber }}
          </p>
        </div>
        <div class="col-md-6">
          <p class="m-0 size12">Email</p>
          <p class="mt-0 mb-2 bold w3-text-black">
            {{ subaccount.Email }}
          </p>
        </div>
      </div>
      <div
        class="w3-bar z-index-3 w3-border-bottom"
        style="margin-bottom: -5px"
      >
        <button
          class="w3-bar-item w3-button hover-effect"
          :class="[
            activeTab === 'SubAccountInvestments'
              ? 'w3-red w3-border w3-hover-red'
              : ' w3-white',
          ]"
          @click="activeTab = 'SubAccountInvestments'"
        >
          Investments
        </button>
        <button
          class="w3-bar-item w3-button hover-effect"
          :class="[
            activeTab === 'SubAccountMI'
              ? 'w3-red w3-border w3-hover-red'
              : ' w3-white',
          ]"
          @click="activeTab = 'SubAccountMI'"
        >
          MI
        </button>
        <!-- <button
          class="w3-bar-item w3-button hover-effect"
          :class="[
            activeTab === 'SubAccountSavings'
              ? 'w3-red  w3-border w3-hover-red'
              : ' w3-white',
          ]"
          @click="activeTab = 'SubAccountSavings'"
        >
          Savings
        </button> -->
      </div>
      <SubAccountInvestments
        v-if="activeTab === 'SubAccountInvestments'"
        :subaccount="subaccount"
      />
      <SubAccountMI
        v-if="activeTab === 'SubAccountMI'"
        :subaccount="subaccount"
      />
    </div>
  </section>
</template>
<script>
import SubAccountInvestments from "@/components/SubAccountInvestments";
import SubAccountMI from "@/components/SubAccountMI";
import { equalHeightColumns } from "@/helpers/utilities";
import { ref, toRef, onBeforeMount } from "vue";
import { useStore } from "vuex";
export default {
  emits: ["close"],
  props: ["subaccount"],
  components: { SubAccountInvestments, SubAccountMI },
  setup(props, { emit }) {
    const SubAccount = toRef(props, "subaccount");
    const title = ref(
      SubAccount.value.FirstName + " " + SubAccount.value.Surname
    );
    const activeTab = ref("SubAccountInvestments");
    const closeDetial = () => {
      emit("close");
    };
    const SubAccountInvestmentsMounted = () => {
      equalHeightColumns();
    };

    onBeforeMount(() => {
      useStore().dispatch("resetIdleTime");
    });
    return {
      closeDetial,
      title,
      SubAccountInvestmentsMounted,
      activeTab,
    };
  },
};
</script>
