<template>
  <div class="row mb-2">
    <div class="col-sm-5">
      <h4 class="bold">{{ fundKey }} Transactions</h4>
    </div>
    <div class="col-sm-7">
      <div class="w3-right mb-2 ml-2">
        <DateRangeFilter @gettransactions="getTransactions" />
      </div>
      <select v-model="fundKey" class="form-control width100 w3-right mb-2">
        <option
          v-for="(key, i) in productType"
          :key="key"
          :value="key"
          :selected="i === 0"
        >
          {{ key }}
        </option>
      </select>
    </div>
  </div>
  <div v-if="fetching">
    <img class="mr-3" src="base/img/loading.gif" width="40" />
    Loading transactions
  </div>
  <div v-else>
    <div v-for="(key, i) in productType" :key="key">
      <div :class="[fundKey === key ? 'd-block' : 'd-none']">
        <TransactionsTable
          v-if="TransactionsList[key]"
          :tableid="'table-' + key + '-' + i"
          :headers="TableHeader"
          :rows="TransactionsList[key]"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, onMounted, onBeforeMount, ref, toRef } from "vue";
import TransactionsTable from "@/components/TransactionsTable";
import DateRangeFilter from "@/components/DateRangeFilter";
import axios from "axios";
export default {
  components: { TransactionsTable, DateRangeFilter },
  props: ["codes", "subaccount", "contactno"],
  setup(props) {
    const store = useStore();
    const fetching = ref(false);
    const Transactions = ref([]);
    const productType = toRef(props, "codes");
    const SubAccount = toRef(props, "subaccount");
    const SubContactNo = toRef(props, "contactno");
    const fundKey = ref("");

    const activeRedemption = ref(productType.value[0]);
    const Period = ref({
      StartDate: "",
      EndDate: "",
    });
    const TransactionALLHistory = () => {
      if (SubContactNo.value) {
        fetching.value = true;
        axios({
          method: "post",
          // timeout: Timer,
          url: "/api/TransactionALLHistory",
          data: {
            UserId: SubContactNo.value,
            StartDate: Period.value.StartDate ?? "",
            EndDate: Period.value.EndDate ?? "",
          },
        })
          .then((response) => {
            fetching.value = false;
            if (response.data.status === "success") {
              Transactions.value = response.data.result;
            } else {
              if (response.data.result) {
                store.dispatch("showAlert", {
                  message: response.data.result,
                  status: "error",
                  showing: true,
                });
              }
            }
          })
          .catch((error) => {
            store.dispatch("showAlert", {
              title: "Error: " + error.code,
              message: error.message,
              status: "error",
              showing: true,
            });
          })
          .finally(() => {
            fetching.value = false;
          });
      }
    };
    TransactionALLHistory();
    const TransactionsList = computed(() => {
      const Trans = {};
      for (let trans in Transactions.value) {
        let FundCode = Transactions.value[trans].FundCode;

        if (typeof Trans[FundCode] === "undefined") {
          Trans[FundCode] = [];
        }
        Trans[FundCode].push(Transactions.value[trans]);
      }
      return Trans;
    });

    const TableHeader = ref([
      { text: "Value Date", value: "ValueDate" },
      { text: "Amount Invested", value: "AmountInvested" },
      { text: "Unit Price", value: "UnitPrice" },
      { text: "Description", value: "Description" },
      { text: "Fund Code", value: "FundCode" },
      { text: "Unit Quantity", value: "UnitQuantity" },
    ]);

    const getTransactions = (period) => {
      Period.value = period;
      TransactionALLHistory();
    };
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    onMounted(() => {
      fundKey.value = productType.value[0];
    });
    return {
      TableHeader,
      Transactions,
      fetching,
      productType,
      getTransactions,
      activeRedemption,
      TransactionsList,
      fundKey,
      SubAccount,
    };
  },
};
</script>
