    <template>
  <teleport to="#modalTeleports">
    <div
      :class="[loadInvestmentIframe ? 'w3-block' : '']"
      class="w3-modal"
      style="padding: 2vh; padding-top: 35px"
    >
      <div
        class="w3-modal-content w3-animate-top w3-center bg-transparent"
        style="width: 95vw"
      >
        <button
          class="w3-button w3-red w3-round w3-round-xxlarge absolute"
          style="top: -30px; right: 10px"
          @click.prevent="closeInvestmentIframe('InvestmentPaymentbox')"
        >
          x
        </button>

        <iframe
          name="InvestmentPaymentbox"
          id="InvestmentPaymentbox"
          style="width: 100%; height: 95vh; border: 0px solid #fff"
        ></iframe>
      </div>
    </div>
  </teleport>
  <img
    class="m-3 fetching"
    v-if="!Products.length"
    src="base/img/loading.gif"
    width="80"
  />
  <form
    @submit.prevent="Payment"
    v-else
    id="InvestmentPaymentForm"
    target="InvestmentPaymentbox"
    method="POST"
    style="max-width: 840px"
  >
    <div
      v-show="activeStep === 'InvestmentType'"
      class="steps"
      data-step="InvestmentType"
    >
      <div class="row">
        <div class="col-md-5 pb-3">
          <div class="size15 w3-text-grey mb-0">Create a new portfolio</div>
          <h3 class="my-0">Select an investment type</h3>
        </div>
        <div class="col-md-7">
          <div class="row equalHeightColumns">
            <div
              v-for="product in Products"
              :key="product.InvestmentName"
              class="col-sm-6"
            >
              <section
                class="card column hover-effect cursor mt-0 mb-2 w3-border w3-hover-border-red"
                :class="[
                  investment.InvestmentType === product.FundCode
                    ? 'w3-red w3-text-white'
                    : 'w3-white',
                ]"
                @click="
                  SelectInvestment(
                    product.FundCode,
                    product.Offer,
                    product.InvestmentName
                  )
                "
              >
                <div class="card-body bg-transparent m-0 px-3 py-2">
                  <h6 class="bold mb-0 ellipsis">
                    {{ product.InvestmentName }}
                  </h6>
                  <div class="m-0 size14 bold">
                    {{ formattedCurrency(product.Offer, "&#8358;") }}/unit
                  </div>
                  <div class="m-0 size12">
                    {{ product.ValueDate.substring(0, 10) }}
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div class="actions">
            <button
              type="button"
              class="nextStep w3-button w3-red w3-hover-orange"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="activeStep === 'InvestmentAmount'"
      data-step="InvestmentAmount"
      class="steps"
    >
      <div class="row">
        <div class="col-md-6 pb-3">
          <h3 class="mt-0">How much would you like to invest?</h3>
          <div v-html="'E.g. 50,000'" class="size14"></div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label size13">Amount to invest</label>
            <div class="input-group max-width250">
              <span v-html="SETTINGS._CURRENCY" class="input-group-text"></span>
              <input
                name="InvestmentAmount"
                @change="updateUnit"
                v-model="investment.InvestmentAmount"
                class="form-control form-control-lg currency"
              />
            </div>
            <div class="mt-1">
              {{
                InvestmentUnits ? investment.InvestmentUnits.toFixed(4) : "0"
              }}
            </div>
          </div>

          <div class="actions">
            <button
              type="button"
              class="prevStep w3-button w3-light-grey w3-hover-white mr-2"
            >
              Back</button
            ><button
              type="button"
              class="nextStep w3-button w3-red w3-hover-orange"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-show="activeStep === 'Payment'" class="steps" data-step="Payment">
      <div class="row equalHeightColumns">
        <div class="col-md-6 column w3-theme-d5">
          <div class="m-1 px-4 py-4 w3-round-large">
            <label class="control-label w3-text-orange">
              Investment Amount</label
            >
            <h3 class="mt-0 mb-3 bold">
              {{ formattedCurrency(InvestmentAmount, "&#8358;") }}
            </h3>
            <label class="control-label w3-text-orange"> Total units</label>
            <h3 class="mt-0 mb-3">
              {{
                InvestmentUnits ? investment.InvestmentUnits.toFixed(4) : "0"
              }}
            </h3>

            <label class="control-label w3-text-orange"> Investment Type</label>
            <h5 class="mt-0">
              {{ investment.InvestmentName }} ({{ investment.InvestmentType }})
            </h5>
          </div>
        </div>
        <div class="col-md-6 column">
          <div class="m-1 px-4 py-4">
            <h3 class="mt-0 mb-4">Payment</h3>

            <p>
              You will be prompted to choose a payment method and provide your
              billing information.
            </p>
            <p>Please click the button below to proceed</p>

            <div class="actions">
              <button
                type="button"
                class="prevStep w3-button w3-light-grey w3-hover-white mr-2"
              >
                Back
              </button>
              <button type="submit" class="w3-button w3-red w3-hover-orange">
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input type="hidden" :value="uuid()" name="uuid" />
    <input type="hidden" name="TransRef" v-model="investment.TransRef" />
    <input type="hidden" name="ContactNo" v-model="ContactNo" />
    <input type="hidden" name="Email" v-model="Email" />
    <input type="hidden" name="PhoneNo" v-model="PhoneNo" />
    <input type="hidden" name="Fullname" v-model="Fullname" />
    <input type="hidden" name="InvestmentUnits" v-model="InvestmentUnits" />
    <input type="hidden" name="callback" :value="callback" />

    <input
      type="hidden"
      name="PaymentTitle"
      :value="'Payment for ' + investment.InvestmentName + ' investment'"
    />
    <input
      type="hidden"
      name="InvestmentName"
      v-model="investment.InvestmentName"
    />
    <input
      type="hidden"
      name="InvestmentType"
      v-model="investment.InvestmentType"
    />
    <input
      type="hidden"
      name="InvestmentOffer"
      v-model="investment.InvestmentOffer"
    />
  </form>
</template>
<script>
import formatCurrency from "format-currency";
import "@dmuy/jquery-datepicker/duDatepicker.css";
import duDatepicker from "@dmuy/jquery-datepicker";
import { formattedCurrency, backendURL } from "@/helpers/utilities";
import { v4 as uuid } from "uuid";
import $ from "jquery";
import { ref, computed, onMounted, onBeforeMount, toRef } from "vue";
import { useStore } from "vuex";
import usePayments from "@/composables/use-payments";

export default {
  components: {},
  props: ["callback", "fullname", "email", "contactno", "phoneno"],
  emits: ["finished"],
  setup(props, { emit }) {
    const store = useStore();
    const title = ref("Add a new investment");
    const SETTINGS = computed(() => store.state.appSettings);
    const Callback = toRef(props, "callback");
    const Fullname = toRef(props, "fullname");
    const Email = toRef(props, "email");
    const ContactNo = toRef(props, "contactno");
    const PhoneNo = toRef(props, "phoneno");

    const MIFundCode = SETTINGS.value._MURABABA_PLAN_CODE;

    const activeStep = ref("InvestmentType");

    const FundProducts = computed(() => store.state.Products);
    const Products = computed(() => {
      const MI = [];
      const INV = [];
      for (let key in FundProducts.value) {
        if (FundProducts.value[key].FundCode === MIFundCode) {
          MI.push(FundProducts.value[key]);
        } else {
          INV.push(FundProducts.value[key]);
        }
      }
      return Callback.value === "mi" ? MI : INV;
    });
    const investment = ref({
      TransRef: "",
      InvestmentName: "",
      InvestmentType: "",
      InvestmentOffer: "",
      InvestmentUnits: 0,
      InvestmentAmount: 0,
      TempContactNo: ContactNo.value,
    });
    const InvestmentUnits = computed(() =>
      investment.value.InvestmentOffer
        ? InvestmentAmount.value / investment.value.InvestmentOffer
        : 0
    );
    const InvestmentAmount = computed(() =>
      investment.value.InvestmentAmount
        ? investment.value.InvestmentAmount.replace(/,/g, "")
        : 0
    );

    const SelectInvestment = (inv, offer, InvestmentName) => {
      investment.value.InvestmentName = InvestmentName;
      investment.value.InvestmentType = inv;
      investment.value.InvestmentOffer = offer;
    };
    const updateUnit = () => {
      investment.value.InvestmentUnits = investment.value.InvestmentOffer
        ? InvestmentAmount.value / investment.value.InvestmentOffer
        : 0;
    };
    const retry = ref(1);
    const PaymentSuccess = (data) => {
      investment.value.TransRef = data.Trx.PaymentRef;
      if (data.Trx.apiCalled === 1) {
        store.dispatch("Dashboard");
        console.log("emit finished");
        emit("finished");
      } else {
        if (retry.value <= 3) {
          retry.value++;
          InvestmentIframeCallback(investment.value.TransRef, retry.value);
        }
      }
    };
    const {
      loadIframe: loadInvestmentIframe,
      closeIframe: closeInvestmentIframe,
      IframeCallback: InvestmentIframeCallback,
    } = usePayments(PaymentSuccess);
    const Payment = () => {
      if (!(InvestmentAmount.value > 0)) {
        store.dispatch("showAlert", {
          message: "Please enter an amount to invest",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (InvestmentAmount.value < SETTINGS.value._MIN_AMOUNT) {
        store.dispatch("showAlert", {
          message:
            "Amount to invest cannot be less than " +
            SETTINGS.value._MIN_AMOUNT,
          status: "error",
          showing: true,
        });
        return false;
      }
      if (!investment.value.InvestmentType) {
        store.dispatch("showAlert", {
          message: "Please select an Investment type",
          status: "error",
          showing: true,
        });
        return false;
      }
      if (investment.value.TransRef) {
        InvestmentIframeCallback(investment.value.TransRef);
        return;
      }
      $("form#InvestmentPaymentForm")
        .attr("target", "InvestmentPaymentbox")
        .attr("action", backendURL + "/payment/paystack/invest")
        .submit();
      loadInvestmentIframe.value = true;
    };
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    onMounted(() => {
      $(".prevStep").on("click", function () {
        const parent = $(this).parents(".steps:first");
        const prev = parent.prev(".steps");
        if (prev.length) {
          $("div.steps").hide();
          prev.fadeIn();
        }
      });
      $(".nextStep").on("click", function () {
        const parent = $(this).parents(".steps:first");
        const next = parent.next(".steps");
        const step = parent.data("step");
        switch (step) {
          case "InvestmentType":
            if (!investment.value.InvestmentType) {
              store.dispatch("showAlert", {
                message: "Please select a fund tupe to invest",
                status: "error",
                showing: true,
              });
              return false;
            }
            break;
          case "InvestmentAmount":
            if (!(InvestmentAmount.value > 0)) {
              store.dispatch("showAlert", {
                message: "Please enter an amount to invest",
                status: "error",
                showing: true,
              });
              return false;
            }
            if (InvestmentAmount.value < SETTINGS.value._MIN_AMOUNT) {
              store.dispatch("showAlert", {
                message:
                  "Amount to invest cannot be less than " +
                  SETTINGS.value._MIN_AMOUNT,
                status: "error",
                showing: true,
              });
              return false;
            }
            break;
        }
        if (next.length) {
          $("div.steps").hide();
          next.fadeIn();
        }
      });

      $(".currency").on("each", function () {
        investment.value[$(this).attr("name")] = formatCurrency($(this).val());
      });
      $(".currency").on("blur", function () {
        investment.value[$(this).attr("name")] = formatCurrency($(this).val());
      });
      $(".currency").on("focus", function () {
        investment.value[$(this).attr("name")] = $(this)
          .val()
          .replace(/,/g, "");
      });
    });
    return {
      title,
      investment,
      InvestmentUnits,
      Fullname,
      Email,
      PhoneNo,

      ContactNo,
      SETTINGS,
      activeStep,
      duDatepicker,
      Products,
      SelectInvestment,
      InvestmentAmount,
      Payment,
      formattedCurrency,
      uuid,
      updateUnit,
      loadInvestmentIframe,
      closeInvestmentIframe,
    };
  },
};
</script>
<style scoped>
.steps {
  padding: 50px 40px;
  background-color: #fff;
}
.actions {
  margin-top: 40px;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}
</style>

