<template>
  <section
    class="investmentSummaryBox card relative column mb-4 w3-border w3-white cursor hover-effect w3-hover-light-grey w3-hover-border-red"
  >
    <div class="investmentSummary card-body m-0 px-5 p-3 pb-3">
      <h3 class="bold mb-3 ellipsis w3-text-orange">
        {{ subaccount.FirstName }} {{ subaccount.Surname }}
      </h3>

      <div class="row">
        <div class="col-sm-6">
          <p class="m-0 size14 w3-text-orange">Total Investment</p>
          <p class="mt-0 mb-2 size16 bold w3-text-white">
            {{ formatCurrency(subaccount.TotalInvestment, "&#8358;") }}
          </p>
        </div>
        <div class="col-sm-6">
          <p class="m-0 size14 w3-text-orange w3-right-align">
            Account Open Date
          </p>
          <p class="mt-0 mb-2 size16 bold w3-text-white w3-right-align">
            {{ formattedDate(subaccount.AccountOpenDate) }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <p class="m-0 size14 w3-text-orange">Contact No</p>
          <p class="mt-0 mb-2 size16 bold w3-text-white">
            {{ subaccount.SubContactNo }}
          </p>
        </div>
        <div class="col-sm-6">
          <p class="m-0 size14 w3-text-orange w3-right-align">Phone Number</p>
          <p class="mt-0 mb-2 size16 bold w3-text-white w3-right-align">
            {{ subaccount.PhoneNumber }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="m-0 size14 w3-text-orange">Email</p>
          <p class="mt-0 mb-2 size16 bold w3-text-white">
            {{ subaccount.Email }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { onMounted } from "@vue/runtime-core";
import { formatCurrency, formattedDate } from "@/helpers/utilities";
export default {
  emits: ["mounted"],
  props: ["subaccount"],
  setup(_, { emit }) {
    onMounted(() => {
      emit("mounted");
    });
    return {
      formatCurrency,
      formattedDate,
    };
  },
};
</script>
<style scoped>
.investmentSummaryBox {
  max-width: 100%;
}
.investmentSummary {
  background-color: #8d1d1c;
  background-image: url("/base/img/bg-1.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
.investmentSummary p {
  line-height: normal !important;
}
</style>
