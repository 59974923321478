<template>
  <section
    class="investmentSummaryBox card relative column mb-4 w3-border w3-white hover-effect w3-hover-border-red"
  >
    <div class="investmentSummary card-body m-0 px-5 p-3 pb-3">
      <h3 class="bold mb-3 ellipsis w3-text-orange">
        No sub account found
      </h3>

      <div class="row"> 
        <div class="col-sm-6">
          <p class="m-0 size14 w3-text-orange">Total</p>
          <p class="mt-0 mb-2 size16 bold w3-text-white">
            -
          </p>
        </div>
        <div class="col-sm-6">
          <p class="m-0 size14 w3-text-orange w3-right-align">
            Account Date
          </p>
          <p class="mt-0 mb-2 size16 bold w3-text-white w3-right-align">
            -
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <p class="m-0 size14 w3-text-orange">Contact No</p>
          <p class="mt-0 mb-2 size16 bold w3-text-white">
            -
          </p>
        </div>
        <div class="col-sm-6">
          <p class="m-0 size14 w3-text-orange w3-right-align">Phone Number</p>
          <p class="mt-0 mb-2 size16 bold w3-text-white w3-right-align">
            -
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="m-0 size14 w3-text-orange">Email</p>
          <p class="mt-0 mb-0 size16 bold w3-text-white">
            -
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.investmentSummaryBox {
  max-width: 100%;
}
.investmentSummary {
  background-color: #8d1d1c;
  background-image: url("/base/img/bg-1.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
.investmentSummary p {
  line-height: normal !important;
}
</style>
