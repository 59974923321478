<template>
  <div
    v-if="loadDetail"
    class="investment_Detail relative w3-white mb-5 box-shadow w3-animate-top max-width840"
  >
    <div v-if="activeInvestment" class="absolute top-0 right-0 z-index-3">
      <a
        href="javascript:;"
        @click="closeInvestmentDetail"
        style="top: -10px"
        class="w3-button w3-red w3-hover-deep-orange hover-effect-tiny no-hover-underline w3-right"
      >
        x</a
      >
      <div style="clear: both"></div>
    </div>

    <MIInvestmentDetailPage
      referral="mi"
      :investment="activeInvestment"
      :contactno="ContactNo"
      :email="Email"
      :fullname="Fullname"
      :phoneno="PhoneNo"
      :valuofinvestment="activeInvestment.ValuOfInvestment"
    ></MIInvestmentDetailPage>
  </div>
  <div v-show="!loadDetail">
    <div v-if="MIRunningBal" class="row equalHeightColumns">
      <template v-for="card in MIRunningBal">
        <div
          v-if="card.AmountInvested > 0"
          :key="card.BookingDate"
          class="col-xl-4 col-md-6"
          @click="showInvestmentDetail(card)"
        >
          <section
            class="card w3-white mb-4 column w3-border cursor hover-effect w3-hover-light-grey w3-hover-border-red"
          >
            <div class="card-body bg-transparent m-0 pb-2">
              <div class="w3-cell-row">
                <div class="w3-cell w3-cell-top">
                  <i
                    :class="[
                      card.MarginAtToday < 0 ? 'w3-text-red' : 'w3-text-green',
                    ]"
                    class="bx bx-line-chart size60"
                    style="font-weight: 100 !important"
                  ></i>
                  <div>
                    <p class="size11 my-0">Margin At Today</p>
                    <p
                      class="size12 bold my-0"
                      :class="[
                        card.MarginAtToday < 0
                          ? 'w3-text-red'
                          : 'w3-text-green',
                      ]"
                    >
                      {{ formatCurrency(card.MarginAtToday) }}
                      <i
                        class="bx bx-tada size18"
                        :class="[
                          card.GrowthIndex < 0
                            ? 'bx-down-arrow-alt'
                            : 'bx-up-arrow-alt',
                        ]"
                      ></i>
                    </p>
                  </div>
                </div>
                <div class="w3-cell w3-cell-top w3-right-align">
                  <p class="mt-0 mb-1 size10 bold">Amount Invested</p>
                  <p class="size14 bold mt-0 mb-3 w3-text-black">
                    {{ formatCurrency(card.AmountInvested, "&#8358;") }}
                  </p>

                  <p class="mt-0 mb-1 size10 bold">Investment Value Today</p>
                  <p class="size14 bold my-0 w3-text-black">
                    {{ formatCurrency(card.InvestmentValueAtToday, "&#8358;") }}
                  </p>
                  <p class="mt-0 mb-1 size10 bold">
                    Expected Value At Maturity
                  </p>
                  <p class="size14 bold my-0 w3-text-black">
                    {{
                      formatCurrency(card.ExpectedValueAtMaturity, "&#8358;")
                    }}
                  </p>
                </div>
              </div>
              <div class="w3-cell-row pt-2 mt-3 w3-border-top">
                <div class="w3-cell w3-cell-top">
                  <p class="mt-0 mb-1 size10">Booking Date</p>
                  <p class="size14 my-0 bold w3-text-black">
                    {{ card.BookingDate.substring(0, 10) }}
                  </p>
                </div>

                <div class="w3-cell w3-cell-top w3-right-align">
                  <p class="mt-0 mb-1 size11">Maturity Date</p>
                  <p class="size14 my-0 bold w3-text-black">
                    {{ card.MaturityDate.substring(0, 10) }}
                  </p>
                </div>
                <div class="w3-cell w3-cell-top w3-right-align">
                  <p class="mt-0 mb-1 size11">Tenor</p>
                  <p class="size14 my-0 bold w3-text-black">
                    {{ card.Tenor }} days
                  </p>
                </div>
              </div>
            </div>
         <div class="px-3 py-2 size13 mt-1 w3-center w3-theme-d4"><span class="bx-flashingg"><i class='bx bx-info-circle' ></i> Click for Investment detail, Topup and Liquidation</span></div>
         </section>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { formatCurrency } from "@/helpers/utilities";
// import $ from "jquery";
import { ref, toRef } from "vue";
import MIInvestmentDetailPage from "@/components/MIInvestmentDetailPage";
export default {
  components: {
    MIInvestmentDetailPage,
  },
  props: ["referral", "data", "subaccount"],
  setup(props) {
    const MIRunningBal = toRef(props, "data");
    const activeInvestment = ref({});
    const loadDetail = ref(false);
    const SubAccount = toRef(props, "subaccount");
    const Fullname = ref(
      SubAccount.value.Surname + " " + SubAccount.value.FirstName
    );
    const Email = ref(SubAccount.value.Email);
    const ContactNo = ref(SubAccount.value.SubContactNo);
    const PhoneNo = ref(SubAccount.value.PhoneNumber);
    const showInvestmentDetail = (Investment) => {
      activeInvestment.value = Investment;
      loadDetail.value = true;
    };
    const closeInvestmentDetail = () => {
      loadDetail.value = false;
    };
    return {
      formatCurrency,
      MIRunningBal,
      activeInvestment,
      loadDetail,
      showInvestmentDetail,
      closeInvestmentDetail,
      Fullname,
      Email,
      ContactNo,
      PhoneNo,
      SubAccount,
    };
  },
};
</script>
<style scoped>
p {
  line-height: normal !important;
}
</style>
