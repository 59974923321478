<template>
  <section
    v-if="total"
    class="investmentSummaryBox card shadow w3-border mb-4"
    :class="[classes ? classes : '']"
  >
    <div class="investmentSummary card-body m-0 py-2 px-4">
      <div class="center-vertical pt-4">
        <i class="bx bx-pie-chart-alt-2 size60 w3-left w3-text-orange"></i>
        <div class="ml-2">
          <p class="w3-text-orange mb-3 bold">{{ length }} packages</p>
          <p class="m-0 size11 w3-text-orange">TOTAL AMOUNT INVESTED</p>
          <p class="mt-0 mb-3 w3-text-white">
            {{ formatCurrency(total.TotalAmountInvested, "&#8358;") }}
          </p>
          <p class="m-0 size11 w3-text-orange">TOTAL VALUE OF INVESTMENT</p>
          <p class="mt-0 w3-text-white">
            {{ formatCurrency(total.ValuOfInvestment, "&#8358;") }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { formatCurrency } from "@/helpers/utilities";
export default {
  props: ["total", "classes", "length"],
  setup() {
    return {
      formatCurrency,
    };
  },
};
</script>
<style scoped>
.investmentSummaryBox {
  max-width: 100%;
}
.investmentSummary {
  background-color: #8d1d1c;
  background-image: url("/base/img/bg-1.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
.investmentSummary p {
  line-height: normal !important;
}
</style>