<template>
  <section role="main" class="content-body">
    <header class="page-header">
      <h2>{{ title }}</h2>
    </header>

    <div class="p-4">
      <div v-if="fetching" class="loading_spinner_box">
        <div class="loading_spinner"></div>
      </div>
      <div v-else class="row">
        <div class="col-md-10 w3-border-right">
          <div v-if="show_detail" class="mb-4">
            <SubAccountDetail
              :subaccount="active_subaccount"
              @close="show_detail = false"
            />
          </div>
          <template v-if="!show_detail">
            <div v-if="SubAccounts" class="row equalHeightColumns">
              <div
                v-for="subAccount in SubAccounts"
                :key="subAccount.SubContactNo"
                class="col-md-6"
              >
                <SubAccountCard
                  @click="showDetail(subAccount)"
                  :subaccount="subAccount"
                  @mounted="make_equal_height_columns"
                />
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-6">
                <SubAccountCardEmpty />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, onBeforeMount } from "vue";
import { equalHeightColumns } from "@/helpers/utilities";
import SubAccountCard from "@/components/SubAccountCard";
import SubAccountDetail from "@/components/SubAccountDetail";
import SubAccountCardEmpty from "@/components/SubAccountCardEmpty.vue";

export default {
  components: { SubAccountCard, SubAccountDetail, SubAccountCardEmpty },
  setup() {
    const title = ref("Sub Accounts");
    const store = useStore();
    const fetching = computed(() => store.state.fetching.GetAllSubAccounts);
    const SubAccounts = computed(() => store.state.GetAllSubAccounts);
    if (!SubAccounts?.value?.length) {
      store.dispatch("GetAllSubAccounts");
    }
    const make_equal_height_columns = () => {
      equalHeightColumns();
    };
    const active_subaccount = ref({});
    const show_detail = ref(false);
    const showDetail = (subaccount) => {
      active_subaccount.value = subaccount;
      show_detail.value = true;
    };
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    return {
      title,
      fetching,
      SubAccounts,
      make_equal_height_columns,
      showDetail,
      show_detail,
      active_subaccount,
    };
  },
};
</script>