<template>
  <div class="p-3"><h4 class="bold m-0 w3-text-red">Investments</h4></div>
  <div v-if="newInvestment" class="relative mb-5 w3-white w3-border">
    <a
      href="javascript:;"
      @click.prevent="newInvestment = false"
      style="top: -10px"
      class="w3-button w3-orange hover-effect-tiny w3-border no-hover-underline btn-rounded absolute right-10"
    >
      x</a
    >
    <NewInvestment
      callback="sub-accounts-investments"
      :fullname="SubAccount.FirstName + ' ' + SubAccount.Surname"
      :email="SubAccount.Email"
      :contactno="SubAccount.SubContactNo"
      :phoneno="SubAccount.PhoneNumber"
      @finished="newInvestment = false"
    />
  </div>
  <div v-if="fetching">
    <img class="mr-3" src="base/img/loading.gif" width="40" />
    Loading packages
  </div>
  <template v-else>
    <div class="row equalHeightColumns mb-3">
      <div v-if="Balances" class="col-xl-4 col-md-6">
        <InvestmentSummary
          :total="InvestmentTotal"
          :length="Balances.length"
          :classes="'column mb-4'"
        />
      </div>
      <div class="col-xl-4 col-md-6">
        <NewInvestmentButton
          v-if="!newInvestment"
          @click="newInvestment = true"
          :label="'ADD A NEW PORTFOLIO'"
        />
      </div>
    </div>

    <SubAccountInvestmentsCard
      :data="DashBoard_Data"
      referral="sub-accounts"
      :subaccount="SubAccount"
    />
    <SubAccountTransactions
      :codes="InvestmentCodes"
      :contactno="SubContactNo"
      :subaccount="SubAccount"
      v-if="mounted"
    />
    <hr />
  </template>
</template>
<script>
import InvestmentSummary from "@/components/InvestmentSummary";
import NewInvestmentButton from "@/components/NewInvestmentButton";
import SubAccountInvestmentsCard from "@/components/SubAccountInvestmentsCard";
import SubAccountTransactions from "@/components/SubAccountTransactions";
import NewInvestment from "@/components/NewInvestment.vue";
import { equalHeightColumns } from "@/helpers/utilities";
import { ref, toRef, computed, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import axios from "axios";
export default {
  components: {
    InvestmentSummary,
    NewInvestmentButton,
    SubAccountInvestmentsCard,
    SubAccountTransactions,
    NewInvestment,
  },
  props: ["subaccount"],
  setup(props) {
    const store = useStore();
    const SubAccount = toRef(props, "subaccount");
    const SubContactNo = ref(SubAccount.value.SubContactNo);
    const SETTINGS = computed(() => store.state.appSettings);
    const DashBoard_Data = ref([]);
    const fetching = ref(false);
    const InvestmentCodes = ref([]);
    if (SubContactNo.value) {
      fetching.value = true;
      axios({
        method: "get",
        url: "/api/dashboard_data/" + SubContactNo.value + "/DashBoard",
      })
        .then((response) => {
          fetching.value = false;
          if (response.data.status === "success") {
            const DashBoardData = response.data.result;
            DashBoard_Data.value = DashBoardData;

            for (let inv in DashBoardData) {
              let Code = DashBoardData[inv].FundCode;
              if (
                SETTINGS.value &&
                SETTINGS.value._MURABABA_PLAN_CODE &&
                Code !== SETTINGS.value._MURABABA_PLAN_CODE
              ) {
                InvestmentCodes.value.push(Code);
              }
            }
          } else {
            if (response.data.result) {
              store.dispatch("showAlert", {
                message: response.data.result,
                status: "error",
                showing: true,
              });
            }
          }
        })
        .catch((error) => {
          store.dispatch("showAlert", {
            title: "Error: " + error.code,
            message: error.message,
            status: "error",
            showing: true,
          });
        })
        .finally(() => {
          fetching.value = false;
          equalHeightColumns();
        });
    }
    const newInvestment = ref(false);
    const mounted = ref(false);

    const Balances = computed(() => {
      const INV = [];
      for (let inv in DashBoard_Data.value) {
        if (
          DashBoard_Data.value[inv].FundCode !==
          SETTINGS.value._MURABABA_PLAN_CODE
        ) {
          INV.push(DashBoard_Data.value[inv]);
        }
      }
      return INV;
    });

    const InvestmentTotal = computed(() => {
      let total = {
        ValuOfInvestment: 0,
        TotalAmountInvested: 0,
      };
      for (let inv in Balances.value) {
        total.ValuOfInvestment += parseFloat(
          Balances.value[inv].ValuOfInvestment
        );
        total.TotalAmountInvested += parseFloat(
          Balances.value[inv].TotalAmountInvested
        );
      }
      return total;
    });
    const title = ref("Investments");
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    onMounted(() => {
      mounted.value = true;
    });
    return {
      title,
      Balances,
      fetching,
      InvestmentTotal,
      newInvestment,
      mounted,
      SubContactNo,
      DashBoard_Data,
      InvestmentCodes,
      SubAccount,
    };
  },
};
</script>
