<template>
  <div class="p-3"><h4 class="bold m-0 w3-text-red">MI Investments</h4></div>
  <div v-if="newInvestment" class="relative mb-5 w3-white w3-border">
    <a
      href="javascript:;"
      @click.prevent="newInvestment = false"
      style="top: -10px"
      class="w3-button w3-orange hover-effect-tiny w3-border no-hover-underline btn-rounded absolute right-10"
    >
      x</a
    >
    <NewMIInvestment
      callback="sub-accounts-mi"
      :fullname="SubAccount.FirstName + ' ' + SubAccount.Surname"
      :email="SubAccount.Email"
      :contactno="SubAccount.SubContactNo"
      :phoneno="SubAccount.PhoneNumber"
      @finished="newInvestment = false"
    />
  </div>
  <div v-if="fetching">
    <img class="mr-3" src="base/img/loading.gif" width="40" />
    Loading packages
  </div>
  <template v-else>
    <div class="row equalHeightColumns pb-3 mb-4 w3-border-bottom">
      <template v-if="MIRunningBal">
        <MIInvestmentSummary
          :amount="FundAmounts"
          :length="MIRunningBal.length"
          @mounted="equalHeightColumns"
        />
      </template>
      <div v-if="!newInvestment" class="col-xl-4 col-md-6">
        <NewInvestmentButton
          @click="newInvestment = true"
          :label="'ADD A NEW PORTFOLIO'"
        />
      </div>
    </div>

    <SubAccountMIInvestmentsCard
      :subaccount="SubAccount"
      :data="MIRunningBal"
      referral="sub-accounts"
    />
    <MITransactions v-if="mounted" />
  </template>
</template>
<script>
import MIInvestmentSummary from "@/components/MIInvestmentSummary";
import NewInvestmentButton from "@/components/NewInvestmentButton";
import MITransactions from "@/components/MITransactions";
import SubAccountMIInvestmentsCard from "@/components/SubAccountMIInvestmentsCard";
import { formatter, equalHeightColumns } from "@/helpers/utilities";
import { ref, toRef, computed, onMounted, onBeforeMount } from "vue";
import NewMIInvestment from "@/components/NewMIInvestment.vue";
import axios from "axios";
import { useStore } from "vuex";
export default {
  components: {
    MIInvestmentSummary,
    NewInvestmentButton,
    SubAccountMIInvestmentsCard,
    MITransactions,
    NewMIInvestment,
  },
  props: ["subaccount"],
  setup(props) {
    const store = useStore();
    const SubAccount = toRef(props, "subaccount");
    const SubContactNo = ref(SubAccount.value.SubContactNo);
    const FullName = ref(
      SubAccount.value.FirstName + " " + SubAccount.value.Surname
    );
    const Email = ref(SubAccount.value.Email);
    const MIRunningBal = ref([]);
    const fetching = ref(false);
    if (SubContactNo.value) {
      fetching.value = true;
      axios({
        method: "get",
        url: "/api/dashboard_data/" + SubContactNo.value + "/MIRunningBal",
      })
        .then((response) => {
          fetching.value = false;
          if (response.data.status === "success") {
            MIRunningBal.value = response.data.result;
          } else {
            if (response.data.result) {
              store.dispatch("showAlert", {
                message: response.data.result,
                status: "error",
                showing: true,
              });
            }
          }
        })
        .catch((error) => {
          store.dispatch("showAlert", {
            title: "Error: " + error.code,
            message: error.message,
            status: "error",
            showing: true,
          });
        })
        .finally(() => {
          fetching.value = false;
        });
    }

    const newInvestment = ref(false);
    const format = (num) => formatter.format(num);
    const mounted = ref(false);
    const FundAmounts = computed(() => {
      const totalAmount = {
        totalInvested: 0,
        totalValueToday: 0,
        totalExpected: 0,
        count: 0,
      };
      for (let inv in MIRunningBal.value) {
        totalAmount.totalInvested += parseFloat(
          MIRunningBal.value[inv].AmountInvested
        );
        totalAmount.totalValueToday += parseFloat(
          MIRunningBal.value[inv].InvestmentValueAtToday
        );
        totalAmount.totalExpected += parseFloat(
          MIRunningBal.value[inv].ExpectedValueAtMaturity
        );
        totalAmount.count++;
      }
      return totalAmount;
    });
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    onMounted(() => {
      mounted.value = true;
      equalHeightColumns();
    });
    return {
      MIRunningBal,
      fetching,
      FundAmounts,
      format,
      newInvestment,
      mounted,
      SubContactNo,
      SubAccount,
      FullName,
      Email,
    };
  },
};
</script>
